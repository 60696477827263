import React, { Component } from "react"

import $ from "jquery"
import Cookies from "js-cookie"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormField from "../components/utilities/FormField"

import "./services.scss"

import AdvertisingIcon from "../images/services-icon-advertising.svg";
import InteractiveIcon from "../images/services-icon-interactive.svg";
import PRIcon from "../images/services-icon-pr.svg";
import SocialIcon from "../images/services-icon-social-media.svg";
import ResearchIcon from "../images/services-icon-research.svg";

class ServiceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      contactCompany: "",
    }

    this.submit = this.submit.bind(this)
    this.nameChange = this.nameChange.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.phoneChange = this.phoneChange.bind(this)
    this.companyChange = this.companyChange.bind(this)
  }

  submit(e) {
    e.preventDefault()
    let formObject = $("#heroForm")
    let postDt = new Date()
    let loadDt = new Date()
    //let _sid = 'qq3eu25egawyabolsjpyzcr5';
    let _sid = Cookies.get("ASP.NET_SessionId")
    if (formObject.find('input[name="js"]').length === 0) {
      let str = '<input type="hidden" name="js" value="1" />'
      formObject.append(str)
    }
    if (formObject.find('input[name="ttp"]').length === 0) {
      let str =
        '<input type="hidden" name="ttp" value="' +
        ((postDt - loadDt) / 1000).toString() +
        '" />'
      formObject.append(str)
    }
    if (formObject.find('input[name="sid"]').length === 0) {
      let str = '<input type="hidden" name="sid" value="' + _sid + '" />'
      formObject.append(str)
    } else {
      formObject.find('input[name="sid"]').val(_sid)
    }

    let post_data = formObject.serialize()

    // console.log(post_data);

    this.setState({
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      contactCompany: "",
    })

    $.ajax({
      url: "https://metrix.meritmile.com/email.aspx?ajax=1",
      type: "POST",
      data: post_data,
      cache: false,
      dataType: "json",
      success: function(data) {
        // Success..
        // let r = data[0];
        // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

        // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
        // window.location.href = redirect_url;

        $("#heroForm").hide()
        $(".form-wrapper .thankyou").show()
        window.open(
          "https://mm-stats.com/xibfc",
          "_blank" // <- This is what makes it open in a new tab.
        )
      },
      // Fail..
      error: function(result) {
        let r = result[0]

        formObject
          .find(".feedback")
          .show()
          .addClass("alert-error")
          .html(r.Message)
          .css("display", "block")
          .focus()
        formObject
          .find(".submit")
          .attr("disabled", false)
          .removeClass("disabled")
      },
    })
  }

  nameChange(e) {
    this.setState({ contactName: e.target.value })
  }

  emailChange(e) {
    this.setState({ contactEmail: e.target.value })
  }

  phoneChange(e) {
    this.setState({ contactPhone: e.target.value })
  }

  companyChange(e) {
    this.setState({ contactCompany: e.target.value })
  }

  render() {
    return (
      <form
        id="heroForm"
        name="metrixform"
        method="POST"
        action="https://metrix.meritmile.com/email.aspx"
        onSubmit={this.submit}
      >
        <FormField
          fieldID={"namehero"}
          valChange={this.nameChange}
          val={this.state.contactName}
          type={"text"}
          name={"name"}
          label={"Full Name"}
        />
        <FormField
          fieldID={"emailhero"}
          valChange={this.emailChange}
          val={this.state.contactEmail}
          type={"email"}
          name={"email"}
          label={"Email"}
        />
        <FormField
          fieldID={"companyhero"}
          valChange={this.companyChange}
          val={this.state.contactCompany}
          type={"text"}
          name={"company"}
          label={"Company"}
        />
        <FormField
          fieldID={"telephonehero"}
          valChange={this.phoneChange}
          val={this.state.contactPhone}
          type={"tel"}
          name={"telephone"}
          label={"Telephone"}
        />
        <button type="submit" className="btn btn-white">
          SUBMIT
        </button>
        <div className="form-group">
          <input
            type="hidden"
            name="referrer"
            value="https://www.meritmile.com/services/"
          />
          <input
            type="hidden"
            name="auth"
            value="877389d9-b572-4727-9bc2-9e4a384c0613"
          />
          <input
            type="text"
            className="d-none hu"
            id="human-check"
            name="human-check"
          />
          <div className="feedback"></div>
          <div className="fix"></div>
        </div>
      </form>
    )
  }
}

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image.source_url}
      link={pageContext.link}
    />
    <div className={`${pageContext.slug} services-template`}>
      <div className="lp-hero">
        <div className="container margin-b">
          <div className="row margin-b">
            <div className="col-md-8 hero-title">
              <h1>
                Services
                <span className="bar"></span>
              </h1>
            </div>
            <div className="col-md-4 hero-bg">
              <div className="inside-hero text-center">
                <h2>
                  Let's talk about your brand and vision today!
                </h2>
                <div className="form-wrapper">
                  <ServiceForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Your company needs more than just an advertising agency, you need a thoughtful group of innovative industry and brand experts.</h1>

              <p>Enter, Merit Mile.</p>

              <p>We're a top PR and advertising agency located in Boca Raton, delivering highly effective content, campaigns, and more to our clients world-wide. From top-level company branding and positioning, to in-the-weeds market research and lead generation, our team can turn your company’s unique goals into actionable results.</p>

              <p>But don't take it from us! Read below to learn about how our clients love working with our team.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="twoColQuotes">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="greenQuote">
                <blockquote>
                  "Merit Mile isn't just an agency, they are a true partner with initiative to truly understand our business and products, think outside of the box every step of the journey."
                </blockquote>
                <cite>- Alissa Plumley, Market Dev Manager - Citrix</cite>
              </div>
            </div>
            <div className="col-md-6">
              <div className="greenQuote">
                <blockquote>
                  "The team at Merit Mile is creative, hard working and they deliver results beyond expectations. They know how to leverage content to gain maximum exposure."
                </blockquote>
                <cite>- Eric Tejeda, Marketing Director - PossibleNOW</cite>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="graywrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="text-center">
                Start Creating Business Momentum Today
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <p>
                <a href="#Top" className="btn btn-secondary" style={{ maxWidth: '300px'}}>
                  Get In Touch
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="services-tiles">
        <div className="row no-gutters row-eq-height">
          <div className="col-md-6">
            <div className="tile advertising">
              &nbsp;
            </div>
          </div>
          <div className="col-md-6">
            <div className="serviceWrap">
              <div className="serviceHdr">
                <img src={AdvertisingIcon} alt="Advertising" className="iconServices iconAdvertising" />
                <h2>Advertising<span className="bar"></span></h2>
              </div>
              <p>Work directly with our talented advertising agency to craft the right message and integrated campaigns to reach your customers.</p>
              <a href="/services/advertising/" className="textLink">Learn more about the advertising services we offer</a>
            </div>
          </div>
        </div>
        <div className="row no-gutters row-eq-height">
          <div className="col-md-6 order-md-2">
            <div className="tile interactive">
              &nbsp;
            </div>
          </div>
          <div className="col-md-6">
            <div className="serviceWrap">
              <div className="serviceHdr">
                <img src={InteractiveIcon} alt="Advertising" className="iconServices iconInteractive" />
                <h2>Interactive<span className="bar"></span></h2>
              </div>
              <p>Our South Florida web development team will keep your company ahead of the curve with purpose-built web design and application development.</p>
              <a href="/services/interactive/" className="textLink">Learn more about the interactive services we offer</a>
            </div>
          </div>
        </div>
        <div className="row no-gutters row-eq-height">
          <div className="col-md-6">
            <div className="tile pr">
              &nbsp;
            </div>
          </div>
          <div className="col-md-6">
            <div className="serviceWrap">
              <div className="serviceHdr">
                <img src={PRIcon} alt="Public Relations" className="iconServices iconPR" />
                <h2>Public Relations<span className="bar"></span></h2>
              </div>
              <p>Target local or global audiences with thoughtful editorial content and track meaningful results that impact your ROI.</p>
              <a href="/services/public-relations/" className="textLink">Learn more about the public relations services we offer</a>
            </div>
          </div>
        </div>
        <div className="row no-gutters row-eq-height">
          <div className="col-md-6 order-md-2">
            <div className="tile social">
              &nbsp;
            </div>
          </div>
          <div className="col-md-6">
            <div className="serviceWrap">
              <div className="serviceHdr">
                <img src={SocialIcon} alt="Social Media" className="iconServices iconSocial" />
                <h2>Social Media<span className="bar"></span></h2>
              </div>
              <p>Shape your consumers’ attitudes and create influencers while generating awareness among targeted decision makers.</p>
              <a href="/services/social-media/" className="textLink">Learn more about the social media services we offer</a>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="tile research">
              &nbsp;
            </div>
          </div>
          <div className="col-md-6">
            <div className="serviceWrap">
              <div className="serviceHdr">
                <img src={ResearchIcon} alt="Research" className="iconServices iconResearch" />
                <h2>Research<span className="bar"></span></h2>
              </div>
              <p>Reveal unknown opportunities and optimize existing strategies through customized and integrated market research campaigns.</p>
              <a href="/services/research/" className="textLink">Learn more about the research services we offer</a>
            </div>
          </div>
        </div>
      </div>

      <div className="brand-wrapper">
        <div className="container-fluid p-0">
            <div className="row">
              <div className="col">
                <h2>Other Services</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>Analytics and site tracking  |  Search engine marketing  |  Facebook and LinkedIn advertising</p>
                <p>Photography and video production  |  Copywriting and content plans  |  Brand identity exploration </p>
                <p>Marketing automation platform (Salesforce, Pardot, Marketo) support  |  Corporate messaging and positioning </p>
              </div>
            </div>
        </div>
      </div>

    </div>
  </Layout>
)
